







































































































.example {

    .swiper {
        width: 100%;

        .swiper-slide {

            justify-content: space-between;
            align-items: center;
            text-align: center;
            font-weight: bold;

            .swiper-zoom-container{
                justify-content: space-between;
                      @media screen and (max-width:767px){
  justify-content: flex-end;

                      }
            }
        }
    }

    .swiper-pagination{
      position: relative !important;
    }

    .toolbar {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 2rem;

        button {
            flex: 1;
            padding: 0;
            margin: 0;

            cursor: pointer;
            &:last-child {
                border: none;
            }

            &:hover {}
        }
    }
}
