// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  @extend %font-sans-regular;
  @include font-values(15,15);
  letter-spacing: 0.04em;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: solid 1px;
  padding: 0 20px;
  border-radius: 3px;
  line-height: 45px;
  text-align: center;
  transition: all 0.6s ease-in-out;

  &__primary {
    background-color: $brand-color;
    border-color: $brand-color;
    color: #fff;
  }
}

.tabs {
  @extend %font-sans-regular;
  @include font-values(18,18);
}
