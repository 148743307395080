// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts


/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

// Mixin: anti-alias text

@mixin alias (
  $style: 'antialias'
) {
  @if $style == 'normal' {
    -webkit-font-smoothing: subpixel-antialiased;
  } @else {
    -webkit-font-smoothing: antialiased;
  }
}
/// Mixin: font sizing in both rems and px

@mixin font-values (
  $sizeValue: 16, $lineValue: 24) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue / 16) + rem;
  line-height: ($lineValue / $sizeValue);
}

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}

/// Boxsizing

@mixin box-sizing($type) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

/// clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
