// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  @include box-sizing(border-box);
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: border-box;
}

/**
 * Basic styles for links
 */
a {
  color: $text-color;
  text-decoration: none;
  transition: color 0.5s ease;

  & img {
    margin-bottom: -6px;
  }
}

a {
  &.current {
    color: $text-color;
    border-bottom: 1px solid $text-color;
  }
}
li, p {
  &.current {
    a {
      color: $text-color;
      border-bottom: 1px solid $text-color;
    }
  }
}
