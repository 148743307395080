.bg--light {
  background: $light-grey;
  color: $text-color;
}
.background--medium {
  background: $mid-grey;
  color: $dark-grey;
}
.background--dark {
  background: $dark-grey;
  color: $light-grey;
}
.background--highlight {
  background: $highlight-color;
  color: $dark-grey;
}
.background--alt {
  background: $bg-color-alt;
  color: $text-color-alt;
}
