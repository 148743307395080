/**
 * Basic typography style for copy text
 */
 // FONT CLASSES

 @import url(https://fonts.googleapis.com/css?family=Montserrat);

%font-sans-regular {
  font-family: $regular-font-stack;
  font-weight: 300;
  font-style: normal;
  @include alias;
}

%font-sans-light {
  font-family: $regular-font-stack;
  font-weight: 200;
  font-style: normal;
  @include alias;
}

%font-sans-semi-bold {
  font-family: $regular-font-stack;
  font-weight: 600;
  font-style: normal;
  @include alias;
}

%font-sans-bold {
  font-family: $regular-font-stack;
  font-weight: 700;
  font-style: normal;
  @include alias;
}

%font-alt-regular {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 400;
  @include alias;
}

%font-alt-bold {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 700;
  @include alias;
}

body {
  background: #fff;
  color: $text-color;
  @extend %font-sans-regular;
  @include font-values(24,27);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  hyphens: none;
}

h1 {
  @extend %font-sans-bold;
  @include fluid-type(46px, 92px);

  @media screen and (max-width:1100px){
    @include fluid-type(46px, 72px);
  }
}

h2 {
  @extend %font-sans-regular;
  @include fluid-type(24px, 34px);
}

h3 {
  @extend %font-sans-bold;
  @include fluid-type(20px, 20px);
}

h4 {
  @extend %font-sans-semi-bold;
  @include font-values(14,14);
  margin: 0;
}

h5 {
  @extend %font-sans-regular;
  @include font-values(14,14);
}

h6{
  @extend %font-sans-regular;
  @include fluid-type(14px, 16px);
  line-height: 1.2;
}

.small {
  @include font-values(14,17);
}
.medium {
  @include font-values(16,18);
  line-height: 1.3;
}
.big {
  @include font-values(24,27);
  line-height: 1.3;
}
.huge {
  @include font-values(27,30);
  line-height: 1.3;
}
p {
  @include fluid-type(14px, 16px);
  margin-top: 0;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.7rem;

  b, strong{
    color: #222427;
  }
}

// Typography helpers
.bold {
  @extend %font-alt-bold;
}
.sans {
  @extend %font-sans-regular;
}
.serif {
  @extend %font-alt-regular;
}
