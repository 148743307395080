@charset 'UTF-8';
@tailwind base;
@tailwind components;
@tailwind utilities;


// 1. Configuration and helpers
@import
  'utils/variables',
  'utils/functions',
  'utils/swiper',
  'utils/mixins';

// 2. Vendors
@import
  'vendor/normalize',
  'vendor/plugins';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/general';

// 4. Layout-related sections
@import
  'layout/layout',
  'layout/header',
  'layout/footer',
  'layout/content',
  'layout/nav';

// 5. Components
@import
  'components/button',
  'components/effects',
  'components/forms',
  'components/global',
  'components/images';

// 6. Page-specific styles
